import { createAction, props } from '@ngrx/store';
import { iIncome } from 'src/app/mini-apps/expense-app/common/interface';

export enum IncomeActionTypes {
  INCOME_LIST_GET = 'Get Income',
  INCOME_LIST_LOAD = 'Load Income',
  ADD_INCOME = 'ADD INCOME',
  ADD_INCOME_SUCCESS = 'ADD INCOME SUCCESS',
  UPDATE_INCOME = 'UPDATE INCOME',
  UPDATE_INCOME_SUCCESS = 'UPDATE INCOME SUCCESS',
  DELETE_INCOME = 'DELETE INCOME',
  DELETE_INCOME_SUCCESS = 'DELETE INCOME SUCCESS',
}

export const GetIncomeAction = createAction(IncomeActionTypes.INCOME_LIST_GET);

export const LoadIncomeAction = createAction(
  IncomeActionTypes.INCOME_LIST_LOAD,
  props<{ income: iIncome[] }>(),
);

export const AddIncomeAction = createAction(
  IncomeActionTypes.ADD_INCOME,
  props<{ income: iIncome }>(),
);

export const AddIncomeSuccessAction = createAction(
  IncomeActionTypes.ADD_INCOME_SUCCESS,
  props<{ income: iIncome }>(),
);

export const UpdateIncomeAction = createAction(
  IncomeActionTypes.UPDATE_INCOME,
  props<{ income: iIncome }>(),
);

export const UpdateIncomeSuccessAction = createAction(
  IncomeActionTypes.UPDATE_INCOME_SUCCESS,
  props<{ income: iIncome }>(),
);

export const DeleteIncomeAction = createAction(
  IncomeActionTypes.DELETE_INCOME,
  props<{ income: iIncome }>(),
);

export const DeleteIncomeSuccessAction = createAction(
  IncomeActionTypes.DELETE_INCOME_SUCCESS,
  props<{ income: iIncome }>(),
);

export const ResetIncomeStore = createAction('Reset Income Store');
